body {
    margin: 0 auto;
    font-family: Arial, sans-serif;
    max-width: 400px;
    padding: 0 20px;
}

.header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100px;
}

.header-logo .logo-svg {
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    height: 185px;
    width: 185px;
}

#uploadForm {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.form-group input[readonly] {
    background-color: #f0f0f0;
}

.section-name {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section-name b {
    margin-right: 0.2rem;
    color: orangered;
    font-size: 0.5rem;
}

.label-description {
    font-size: 0.7rem;
    color: #666;
}

.label-title {
    display: block;
    margin-bottom: 0.5rem;
}

.input-text {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
}

.input-wrapper {
    display: flex;
    align-items: center;
    border: 2px solid;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.input-wrapper > input {
    padding: 0.5rem;
    border: none;
    outline: none;
    flex-grow: 1;
}

.select-container {
    display: flex;
    align-items: center;
    border: 2px solid;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.select-container input[type="file"] {
    display: none;
}

.select-container > label:first-of-type {
    display: flex;
    width: 100%;
    order: 1;
}

.select-container > label:last-of-type {
    order: 2;
}

#address,
#detailAddress {
    padding: 0.5rem;
    border: none;
    outline: none;
    flex-grow: 1;
}

#searchAddress {
    display: none;
}

label.search {
    padding: 0.4rem 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    white-space: nowrap;
}

.file-name {
    padding: 0.5rem;
    width: 100%;
    border: none;
    outline: none;
}

#submitButton {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    background-color: #3e9ca8;
    color: #c3e1e5;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
}

#error-message {
    color: red;
    margin-top: 10px;
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
}

.spinner-image {
    width: 100px;
    height: 100px;
    animation: fade 1s linear infinite alternate;
}

.none-spin {
    opacity: 0;
    pointer-events: none;
}

@keyframes fade {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.tag {
    font-size: 0.9rem;
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 2px 5px;
    margin: 0 2px;
    display: flex;
    align-items: center;
}

.tag-close {
    margin-left: 5px;
    cursor: pointer;
}

#tagInput {
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 60px;
}

.referral-display {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.referral-display > p {
    margin: 0;
}

#service-description {
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#service-description > p {
    font-size: 0.7rem;
    color: gray;
}

.image-container {
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.image-container img {
    width: 70%;
    height: auto;
}
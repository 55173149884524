body {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  font-family: Arial, sans-serif;
}

.header-logo {
  justify-content: center;
  align-items: center;
  height: 100px;
  display: flex;
  overflow: hidden;
}

.header-logo .logo-svg {
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 185px;
  top: 0;
  left: 0;
}

#uploadForm {
  flex-direction: column;
  display: flex;
}

.form-group {
  flex-direction: column;
  margin-bottom: 1rem;
  display: flex;
}

.form-group input[readonly] {
  background-color: #f0f0f0;
}

.section-name {
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.section-name b {
  color: #ff4500;
  margin-right: .2rem;
  font-size: .5rem;
}

.label-description {
  color: #666;
  font-size: .7rem;
}

.label-title {
  margin-bottom: .5rem;
  display: block;
}

.input-text {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: .5rem;
}

.checkbox-group {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.checkbox-group label {
  align-items: center;
  display: flex;
}

.input-wrapper {
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.input-wrapper > input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: .5rem;
}

.select-container {
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.select-container input[type="file"] {
  display: none;
}

.select-container > label:first-of-type {
  order: 1;
  width: 100%;
  display: flex;
}

.select-container > label:last-of-type {
  order: 2;
}

#address, #detailAddress {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: .5rem;
}

#searchAddress {
  display: none;
}

label.search {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background-color: #000;
  padding: .4rem 1rem;
}

.file-name {
  border: none;
  outline: none;
  width: 100%;
  padding: .5rem;
}

#submitButton {
  color: #c3e1e5;
  cursor: pointer;
  background-color: #3e9ca8;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  padding: .5rem 1rem;
  font-size: 1.5rem;
}

#error-message {
  color: red;
  margin-top: 10px;
}

.spinner-overlay {
  z-index: 9999;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner-image {
  width: 100px;
  height: 100px;
  animation: 1s linear infinite alternate fade;
}

.none-spin {
  opacity: 0;
  pointer-events: none;
}

@keyframes fade {
  0% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.tag {
  background-color: #f0f0f0;
  border-radius: 3px;
  align-items: center;
  margin: 0 2px;
  padding: 2px 5px;
  font-size: .9rem;
  display: flex;
}

.tag-close {
  cursor: pointer;
  margin-left: 5px;
}

#tagInput {
  border: none;
  outline: none;
  flex-grow: 1;
  min-width: 60px;
}

.referral-display {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.referral-display > p {
  margin: 0;
}

#service-description {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  display: flex;
}

#service-description > p {
  color: gray;
  font-size: .7rem;
}

.image-container {
  justify-content: center;
  align-items: center;
  max-width: 400px;
  display: flex;
}

.image-container img {
  width: 70%;
  height: auto;
}
/*# sourceMappingURL=sign-up.css.map */
